import React from 'react'
import PseudoBox from '../PseudoBox'
import Box from '../Box'
import Text from '../Text'
import { useColors } from '../theme/Color'
import PropTypes from 'prop-types'

const SubmissionsTextArea = ({
  placeholder,
  maxLength,
  value,
  onInput = () => {},
  border = null,
  borderRadius = null,
  bottomLabelColor = null,
  ...restProps
}) => {
  const { main } = useColors()
  const charactersUntilLimit = maxLength - value.length

  return (
    <>
      <PseudoBox
        as="textarea"
        resize="none"
        display="block"
        bg={main.dark}
        borderTopRightRadius={borderRadius}
        borderTopLeftRadius={borderRadius}
        borderTop={border}
        borderRight={border}
        borderLeft={border}
        w="100%"
        h="150px"
        placeholder={placeholder}
        _focus={{ outline: 'none' }}
        _placeholder={{ opacity: '0.7' }}
        lineHeight="20px"
        p="12px"
        maxLength={maxLength}
        onInput={e => {
          onInput(e)
        }}
        value={value}
        {...restProps}
      />
      <Text
        bg={main.dark}
        fontSize="11px"
        lineHeight="14px"
        textAlign="right"
        p="12px"
        borderBottomRightRadius={borderRadius}
        borderBottomLeftRadius={borderRadius}
        borderRight={border}
        borderLeft={border}
        borderBottom={border}
      >
        <Box as="span" color={bottomLabelColor}>
          {charactersUntilLimit} tegn gjenstår
        </Box>
      </Text>
    </>
  )
}

SubmissionsTextArea.propTypes = {
  placeholder: PropTypes.string,
  maxLength: PropTypes.number,
  value: PropTypes.string,
  onInput: PropTypes.func,
  border: PropTypes.string,
  borderRadius: PropTypes.string,
  bottomLabelColor: PropTypes.string
}

export default SubmissionsTextArea
