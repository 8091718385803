import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import CustomButton from '../../RadioUI/CustomButton'
import Icon from '../../RadioUI/Icon'
import { useHasAcceptedSubmissionTerms, useIsLoggedIn } from '../Auth/AuthContext'
import {
  useFetchSeriesSubmissions,
  useSeriesSubmissions,
  useSeriesSubmissionsSettings
} from '../SeriesSubmissions/SeriesSubmissionsContext'
import Text from '../../RadioUI/Text'

const EpisodeMenuSeriesSubmissions = ({ isHiddenState, setIsHiddenState, seriesId, seriesTitle, ...restProps }) => {
  const {
    loginDialog,
    acceptTermsDialog,
    submissionDialog,
    setCurrentSeriesSubmissionsMetadata
  } = useSeriesSubmissions()
  const fetchSeriesSubmissions = useFetchSeriesSubmissions(seriesId)
  const seriesSubmissionsSettings = useSeriesSubmissionsSettings(seriesId)

  const isLoggedIn = useIsLoggedIn()
  const hasAcceptedSubmissionTerms = useHasAcceptedSubmissionTerms()

  useEffect(() => {
    if (!isHiddenState) {
      if (!seriesSubmissionsSettings) {
        fetchSeriesSubmissions()
      } else if (!seriesSubmissionsSettings.loading && !seriesSubmissionsSettings.error) {
        setCurrentSeriesSubmissionsMetadata({
          seriesId,
          seriesTitle,
          submissions: seriesSubmissionsSettings.submissions
        })
      }
    }
  }, [
    isHiddenState,
    seriesSubmissionsSettings,
    fetchSeriesSubmissions,
    seriesId,
    seriesTitle,
    setCurrentSeriesSubmissionsMetadata
  ])

  const hanleClick = () => {
    setIsHiddenState(true)
    if (!isLoggedIn) {
      loginDialog.showModal()
    } else if (!hasAcceptedSubmissionTerms) {
      acceptTermsDialog.showModal()
    } else {
      submissionDialog.showModal()
    }
  }

  if (
    !seriesSubmissionsSettings ||
    seriesSubmissionsSettings.loading ||
    seriesSubmissionsSettings.error ||
    !seriesSubmissionsSettings.submissions.enabled ||
    !seriesSubmissionsSettings.submissions.text.enabled
  ) {
    return null
  }

  return (
    <>
      <CustomButton
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        size="md"
        px="10px"
        my="4px"
        onClick={hanleClick}
        {...restProps}
      >
        <Text px="8px">Send inn</Text>
        <Icon aria-hidden={true} iconId="nrk-new-chat" pr="4px" />
      </CustomButton>
    </>
  )
}

EpisodeMenuSeriesSubmissions.propTypes = {
  isHiddenState: PropTypes.bool.isRequired,
  setIsHiddenState: PropTypes.func.isRequired,
  seriesId: PropTypes.string,
  seriesTitle: PropTypes.string
}
export default EpisodeMenuSeriesSubmissions
