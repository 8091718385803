import PropTypes from 'prop-types'
import React, { useState, useEffect, useContext } from 'react'
import { useDispatch } from 'react-redux'
import {
  deleteProgressByHref,
  fetchProgramProgressById,
  saveProgramProgressById
} from '../../apiClients/personalisation'
import { fetchPlaybackMetadata } from '../../apiClients/psapi'
import { useEpisodesProgressHref } from '../../pages/Series/hooks/seasonHooks'
import { fetchProgramProgressList } from '../../redux/personalisation/progressActions'
import MarkCompletedButton from '../MarkCompleted/MarkCompletedButton'
import { PreviouslyHeardContext } from '../PreviouslyHeard/PreviouslyHeardContext'
const noop = () => {}

const EpisodeMenuMarkCompleted = ({
  contentId,
  isHiddenState,
  isAuthenticated,
  duration,
  isPodCast,
  type,
  setIsHiddenState = noop,
  onUnMarkCompleted = noop,
  onMarkCompleted = noop,
  ...props
}) => {
  const dispatch = useDispatch()
  const episodesProgressHref = useEpisodesProgressHref()
  const { fetchProgress } = useContext(PreviouslyHeardContext)
  const [completed, setCompleted] = useState(false)
  const [durationIso, setDurationIso] = useState('PT0S')
  const progressContentType = type.includes('podcast') ? 'podcastepisode' : 'programs'

  useEffect(() => {
    if (!isHiddenState) {
      fetchProgramProgressById({
        contentId,
        progressContentType
      }).then(progress => {
        setCompleted(progress.finished)
      })
      if (!duration) {
        const id = isPodCast ? contentId.split('|')[1] : contentId
        fetchPlaybackMetadata(id).then(metadata => {
          setDurationIso(metadata.duration)
        })
      }
    }
  }, [contentId, progressContentType, duration, isPodCast, isHiddenState])

  if (!isAuthenticated) {
    return null
  }

  return (
    <MarkCompletedButton
      onClick={() => {
        if (completed) {
          return saveProgramProgressById({
            contentId,
            progressContentType,
            position: 'PT0S',
            startPlaybackPosition: 'PT0S'
          }).then(() => {
            fetchProgramProgressById({ contentId, progressContentType }).then(progress => {
              if (progress?._links?.deleteProgress) {
                deleteProgressByHref(progress._links.deleteProgress.href).then(fetchProgress)
              }
            })
            if (episodesProgressHref) {
              dispatch(fetchProgramProgressList({ href: episodesProgressHref }))
            }
            onUnMarkCompleted()
            setIsHiddenState(true)
          })
        }
        saveProgramProgressById({
          contentId,
          progressContentType,
          position: duration || durationIso,
          startPlaybackPosition: duration || durationIso
        }).then(() => {
          if (episodesProgressHref) {
            dispatch(fetchProgramProgressList({ href: episodesProgressHref }))
          }
          onMarkCompleted()
          setIsHiddenState(true)
        })
      }}
      completed={completed}
      {...props}
    />
  )
}

EpisodeMenuMarkCompleted.propTypes = {
  isHiddenState: PropTypes.bool.isRequired,
  setIsHiddenState: PropTypes.func.isRequired,
  contentId: PropTypes.string,
  seriesId: PropTypes.string,
  type: PropTypes.string,
  duration: PropTypes.string,
  onUnMarkCompleted: PropTypes.func,
  onMarkCompleted: PropTypes.func,
  isAuthenticated: PropTypes.bool,
  isPodCast: PropTypes.bool
}
export default EpisodeMenuMarkCompleted
