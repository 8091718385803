import React, { forwardRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import Box from '../Box'
import { Global, css } from '@emotion/react'

const fallbackStyle = css`
  dialog {
    position: absolute;
    left: 0;
    right: 0;
    width: -moz-fit-content;
    width: -webkit-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: -webkit-fit-content;
    height: fit-content;
    margin: auto;
    border: none;
    padding: 0;
    background: white;
    color: black;
    display: block;

    &:not([open]) {
      display: none;
    }
  }

  dialog + .backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.1);
  }

  ._dialog_overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  dialog.fixed {
    position: fixed;
    top: 50%;
    transform: translate(0, -50%);
  }
`

const Dialog = forwardRef(({ children, ...restProps }, ref) => {
  useEffect(() => {
    import('dialog-polyfill').then(dialogPolyfill => {
      dialogPolyfill.default.registerDialog(ref.current)
    })
  }, [ref])
  return (
    <>
      <Global styles={fallbackStyle} />
      <Box as="dialog" ref={ref} {...restProps}>
        {children}
      </Box>
    </>
  )
})

Dialog.propTypes = {
  children: PropTypes.node
}

Dialog.displayName = 'Dialog'

export default Dialog
