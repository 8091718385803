import React, { useId, useImperativeHandle, useRef, useState, forwardRef } from 'react'
import Flex from '../Flex'
import Box from '../Box'
import Text from '../Text'
import { useColors } from '../theme/Color'
import PropTypes from 'prop-types'
import IconButton from '../IconButton'

const SubmissionsLockedFormField = forwardRef(({ label, value, lockhint, ...restProps }, ref) => {
  const { main } = useColors()
  const [lockhintHidden, setLockhintHidden] = useState(true)
  const tooltipId = useId()
  const tooltipRef = useRef()

  const hideTooltip = event => {
    if (lockhintHidden) return
    const toolTipRect = tooltipRef.current.getBoundingClientRect()

    const mouseClickInsideTooltip =
      event.clientX > toolTipRect.left &&
      event.clientX < toolTipRect.right &&
      event.clientY > toolTipRect.top &&
      event.clientY < toolTipRect.bottom

    if (!mouseClickInsideTooltip) {
      setLockhintHidden(true)
    }
  }

  useImperativeHandle(ref, () => ({
    handleScreenClick: hideTooltip
  }))

  return (
    <Flex
      background={main.mediumLight10}
      p="7px 12px"
      borderRadius="3px"
      justifyContent="space-between"
      alignItems="center"
      {...restProps}
      aria-describedby={tooltipId} // tror ikke dette fungerer. Har ikke klart å få fokus hit med voiceover.
    >
      <Box opacity="0.7" overflow="hidden">
        <Text fontSize="11px" fontWeight="400" lineHeight="14px" marginBottom="2px" height="12px">
          {label}
        </Text>
        <Text fontSize="16px" fontWeight="400" lineHeight="20px" overflow="hidden" textOverflow="ellipsis">
          {value}
        </Text>
      </Box>
      <Flex align="center" position="relative" alignSelf="stretch" aria-hidden={lockhint ? 'false' : 'true'}>
        <IconButton
          p="0"
          height="auto"
          iconId="nrk-lock--active"
          opacity="0.7"
          flexShrink="0"
          onClick={() => {
            if (lockhint) {
              setLockhintHidden(!lockhintHidden)
            }
          }}
          {...(!lockhint && { _active: { color: 'inherit' } })}
          _focusVisible={{ outline: '1px solid #fff', outlineOffset: '4px' }}
          aria-label="Vis detaljer"
        />
        <Box
          visibility={lockhintHidden ? 'hidden' : null}
          role="tooltip"
          id={tooltipId}
          ref={tooltipRef}
          as="span"
          position="absolute"
          top="0"
          right="-12px"
          transform="translateY(calc(-100% - 7px - 8px))"
          bg={main.light}
          color={main.dark}
          p="16px"
          borderRadius="6px"
          fontSize="13px"
          fontWeight="400"
          lineHeight="18px"
          width="max-content"
          maxW={{ base: 'calc(90vw - 40px)', sm: '293px' }}
        >
          {lockhint}
        </Box>
        <Box
          visibility={lockhintHidden ? 'hidden' : null}
          className="triangle"
          position="absolute"
          top="calc(-7px - 8px)"
          right="0"
          transform="translateX(-25%)"
          borderRight="8px solid transparent"
          borderBottom="8px solid transparent"
          borderLeft="8px solid transparent"
          borderTop={`8px solid ${main.light}`}
        />
      </Flex>
    </Flex>
  )
})

SubmissionsLockedFormField.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  lockhint: PropTypes.string,
  parentRef: PropTypes.object
}

SubmissionsLockedFormField.displayName = 'SubmissionsLockedFormField'

export default SubmissionsLockedFormField
