import CoreDialog from '@nrk/core-dialog/jsx'
import PropTypes from 'prop-types'
import { Link, generatePath } from 'react-router-dom'
import React, { useState, useContext, useEffect, useRef } from 'react'
import Box from '../../RadioUI/Box'
import Button from '../../RadioUI/Button'
import Portal from '../../RadioUI/Portal'
import PseudoBox from '../../RadioUI/PseudoBox'
import ShareComponent from '../../RadioUI/Share/ShareComponent'
import Text from '../../RadioUI/Text'
import { css } from '@emotion/react'
import { TARGET_TYPE_EPISODE, UP_NEXT_CONTENT_TYPES } from '../../common/types'
import { TogglePlaylistContext } from '../StickyPlayer/context/TooglePlaylistContext'
import { PreviouslyHeardContext } from '../PreviouslyHeard/PreviouslyHeardContext'
import IconButton from '../../RadioUI/IconButton'
import modalStyle from '../../RadioUI/Portal/modalStyle'
import EpisodeMenuFavourite from './EpisodeMenuFavourite'
import EpisodeMenuQueue from './EpisodeMenuQueue'
import QueueToast from '../Queues/QueueToast'
import EpisodeMenuMarkCompleted from './EpisodeMenuMarkCompleted'
import { deleteProgressByHref } from '../../apiClients/personalisation'
import Icon from '../../RadioUI/Icon'
import { linkStyle } from '../../RadioUI/theme/link'
import { useColors } from '../../RadioUI/theme/Color'
import { useIsLoggedIn } from '../Auth/AuthContext'
import EpisodeMenuSeriesSubmissions from './EpisodeMenuSeriesSubmissions'
import usePageScroll from '../../common/disablePageScroll'

const noop = () => {}

const MaybePortal = ({ appendToRoot, children }) => (appendToRoot ? <Portal>{children}</Portal> : children)

MaybePortal.propTypes = {
  appendToRoot: PropTypes.bool,
  children: PropTypes.node
}

const EpisodeMenu = ({
  isHiddenState,
  setIsHiddenState,
  episodeId,
  seriesId,
  episodeTitle,
  seriesTitle,
  channelTitle,
  targetType,
  linkUrl,
  shareUrl,
  duration,
  deleteProgress,
  episodeIsAvailable = true,
  showMarkCompleted = true,
  showRemoveFromHistory = false,
  appendToRoot = true,
  onShareContent = noop,
  onViewQueue = noop,
  onRemoveFromContent = noop,
  onRemoveFromQueue = noop,
  onUnFavourite = noop,
  onFavourite = noop,
  onClickDuplicate = noop,
  onUnMarkCompleted = noop,
  onMarkCompleted = noop,
  onClickTitle = noop,
  onChangeQueue = noop
}) => {
  const isLoggedIn = useIsLoggedIn()
  const [isShareHiddenState, setIsShareHiddenState] = useState(true)
  const [isMessageHiddenState, setIsMessageHiddenState] = useState(true)
  const { isDialogHidden } = useContext(TogglePlaylistContext)
  const { fetchProgress } = useContext(PreviouslyHeardContext)
  const menuRef = useRef()
  const colors = useColors()

  shareUrl = shareUrl || `https://radio.nrk.no${linkUrl}`

  const isPodCast = targetType && targetType.includes('podcast')
  const isEpisode = TARGET_TYPE_EPISODE.EPISODE.includes(targetType)
  const isChannel = targetType && targetType === 'channel'
  const isStandAlone = targetType && targetType === 'standaloneProgram'
  const standAloneEpisodeId = episodeId
  episodeId = isPodCast ? `${seriesId}|${episodeId}` : episodeId || seriesId
  const episodeType = isPodCast ? UP_NEXT_CONTENT_TYPES.PODCAST_EPISODE : UP_NEXT_CONTENT_TYPES.PROGRAMS
  const pageScroll = usePageScroll()

  useEffect(() => {
    if (!isDialogHidden || !isHiddenState || !isShareHiddenState) {
      pageScroll.disable()
    } else {
      pageScroll.enable()
    }
  }, [isHiddenState, isDialogHidden, isShareHiddenState, pageScroll])

  return (
    <PseudoBox className={`queue-dialog`}>
      <QueueToast isHidden={isMessageHiddenState} setIsHidden={setIsMessageHiddenState} />
      {(!isHiddenState || !isShareHiddenState) && (
        <MaybePortal appendToRoot={appendToRoot}>
          <CoreDialog
            css={modalStyle}
            onDialogToggle={event => {
              event.preventDefault()
              setIsHiddenState(!!event.target.hidden)
            }}
            hidden={isHiddenState}
            ref={menuRef}
          >
            <Box d="flex" flexDir="column" p="1.5rem" bg={colors.main.medium} rounded="md" textAlign="center" w="313px">
              <IconButton
                d={{ base: 'block', lg: 'none' }}
                className="close-button"
                aria-label="Lukk episode menyen"
                onClick={() => setIsHiddenState(true)}
                iconId="nrk-close"
                justifyContent="center"
                pos="absolute"
                right="0.5rem"
                top="0.5rem"
                h="24px"
                w="24px"
                size="xs"
                px="0"
                pz="0"
                css={css`
                  span {
                    line-height: 0;
                  }
                `}
              />
              <Link
                to={
                  isChannel
                    ? shareUrl || ''
                    : generatePath('/:type/:seriesId?', {
                        type: isPodCast ? 'podkast' : isStandAlone ? 'program' : 'serie',
                        seriesId
                      })
                }
                onClick={() => onClickTitle()}
                css={linkStyle(colors.main)}
              >
                <Text size="xl" weight="semibold" whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
                  {isChannel ? channelTitle : seriesTitle}
                </Text>
              </Link>

              {!!episodeTitle && (
                <Link
                  to={
                    isChannel
                      ? shareUrl || ''
                      : generatePath('/:type/:seriesId?/:episodeId?', {
                          type: isPodCast ? 'podkast' : isStandAlone ? 'program' : 'serie',
                          seriesId,
                          episodeId: standAloneEpisodeId
                        })
                  }
                  onClick={() => onClickTitle()}
                  css={linkStyle(colors.main)}
                >
                  <Text size="lg" weight="semibold" whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
                    {episodeTitle}
                  </Text>
                </Link>
              )}
              {linkUrl && (
                <ShareComponent
                  isHiddenState={isShareHiddenState}
                  setIsHiddenState={shareHiddenState => {
                    setIsShareHiddenState(shareHiddenState)
                    setIsHiddenState(!shareHiddenState)
                  }}
                  onClick={() => onShareContent()}
                  url={shareUrl}
                  w="100%"
                  mt="24px"
                  mb="4px"
                  flexDir="row-reverse"
                  justifyContent="space-between"
                  px="4"
                  appendToRoot={appendToRoot}
                >
                  Del
                </ShareComponent>
              )}
              {isLoggedIn && !isChannel && isEpisode && episodeIsAvailable && showMarkCompleted && (
                <EpisodeMenuMarkCompleted
                  isHiddenState={isHiddenState}
                  setIsHiddenState={setIsHiddenState}
                  type={targetType}
                  contentId={episodeId}
                  isAuthenticated={isLoggedIn}
                  duration={duration}
                  isPodCast={isPodCast}
                  onUnMarkCompleted={() => {
                    onUnMarkCompleted()
                    fetchProgress()
                  }}
                  onMarkCompleted={() => {
                    onMarkCompleted()
                    fetchProgress()
                  }}
                  w="100%"
                  mt="4px"
                  mb="4px"
                  flexDir="row-reverse"
                  justifyContent="space-between"
                  px="4"
                />
              )}

              {!isChannel && (
                <EpisodeMenuQueue
                  isHiddenState={isHiddenState}
                  setIsHiddenState={setIsHiddenState}
                  episodeId={episodeId}
                  episodeType={episodeType}
                  isAuthenticated={isLoggedIn}
                  isEpisode={isEpisode}
                  episodeIsAvailable={episodeIsAvailable}
                  onViewQueue={onViewQueue}
                  onRemoveFromQueue={onRemoveFromQueue}
                  onClickDuplicate={onClickDuplicate}
                  onChangeQueue={onChangeQueue}
                  setIsMessageHiddenState={setIsMessageHiddenState}
                />
              )}
              {!isChannel && isLoggedIn && deleteProgress && episodeIsAvailable && showRemoveFromHistory && (
                <Button
                  color={colors.warning.light}
                  w="100%"
                  d="flex"
                  mt="4px"
                  mb="4px"
                  flexDir="row"
                  justifyContent="space-between"
                  alignItems="center"
                  onClick={() => {
                    deleteProgressByHref(deleteProgress).then(fetchProgress)
                    onRemoveFromContent()
                    setIsHiddenState(true)
                  }}
                >
                  Fjern fra tidligere hørt
                  <Icon iconId="nrk-trash" />
                </Button>
              )}
              {!isChannel && (
                <EpisodeMenuFavourite
                  isHiddenState={isHiddenState}
                  setIsHiddenState={setIsHiddenState}
                  seriesId={seriesId}
                  isAuthenticated={isLoggedIn}
                  isPodCast={isPodCast}
                  isEpisode={isEpisode}
                  onUnFavourite={onUnFavourite}
                  onFavourite={onFavourite}
                />
              )}
              {!isChannel && (
                <EpisodeMenuSeriesSubmissions
                  isHiddenState={isHiddenState}
                  setIsHiddenState={setIsHiddenState}
                  seriesId={seriesId}
                  seriesTitle={seriesTitle}
                />
              )}
              <Button
                w="100%"
                d="flex"
                mt="20px"
                mb="4px"
                variant="secondary"
                justifyContent="center"
                onClick={e => {
                  e.preventDefault()
                  setIsHiddenState(true)
                }}
              >
                Avbryt
              </Button>
            </Box>
          </CoreDialog>
        </MaybePortal>
      )}
    </PseudoBox>
  )
}

EpisodeMenu.propTypes = {
  isHiddenState: PropTypes.bool.isRequired,
  setIsHiddenState: PropTypes.func.isRequired,
  episodeId: PropTypes.string,
  seriesId: PropTypes.string,
  episodeTitle: PropTypes.string,
  seriesTitle: PropTypes.string,
  channelTitle: PropTypes.string,
  targetType: PropTypes.string,
  linkUrl: PropTypes.string,
  shareUrl: PropTypes.string,
  duration: PropTypes.string,
  deleteProgress: PropTypes.string,
  from: PropTypes.string,
  episodeIsAvailable: PropTypes.bool,
  showMarkCompleted: PropTypes.bool,
  showRemoveFromHistory: PropTypes.bool,
  appendToRoot: PropTypes.bool,
  onClickDuplicate: PropTypes.func,
  onClickTitle: PropTypes.func,
  onShareContent: PropTypes.func,
  onViewQueue: PropTypes.func,
  onRemoveFromContent: PropTypes.func,
  onRemoveFromQueue: PropTypes.func,
  onUnFavourite: PropTypes.func,
  onFavourite: PropTypes.func,
  onChangeQueue: PropTypes.func,
  onUnMarkCompleted: PropTypes.func,
  onMarkCompleted: PropTypes.func
}
export default EpisodeMenu
