import React, { useId } from 'react'
import Box from '../Box'
import Text from '../Text'
import { useColors } from '../theme/Color'
import PropTypes from 'prop-types'
import PseudoBox from '../PseudoBox'
import Flex from '../Flex'
import Icon from '../Icon'
import { css } from '@emotion/react'

const noop = () => {}

const SubmissionsInput = ({
  label,
  value,
  type,
  name,
  required = false,
  pattern,
  warningMessage,
  onInput = noop,
  valid = true,
  ...restProps
}) => {
  const { main, warning } = useColors()
  const tooltipId = useId()

  return (
    <Flex
      background={main.mediumLight10}
      p="7px 12px"
      borderRadius="3px"
      {...restProps}
      border={!valid ? `1px solid ${warning.light}` : null}
    >
      <Box flexGrow="1">
        {value && (
          <Text fontSize="11px" fontWeight="400" lineHeight="14px" marginBottom="2px" height="12px" opacity="0.7">
            {label}
          </Text>
        )}
        <PseudoBox
          as="input"
          aria-describedby={tooltipId}
          bg={main.mediumLight10}
          color={valid ? null : warning.light}
          display="block"
          width="100%"
          borderRadius="3px"
          placeholder={label + (required ? '*' : '')}
          _focus={{ outline: 'none', '::placeholder': { opacity: '0.7' } }}
          py={value ? null : '7px'}
          lineHeight="20px"
          fontSize="16px"
          fontWeight="400"
          onInput={onInput}
          type={type}
          name={name}
          required={required}
          pattern={pattern}
          value={value}
          css={css(`
            &:-webkit-autofill,
            &:-webkit-autofill:hover,
            &:-webkit-autofill:focus,
            &:-webkit-autofill:active {
              -webkit-background-clip: text;
              -webkit-text-fill-color: #ffffff;
            }
          `)}
        />
      </Box>
      {!valid && (
        <Flex align="center" position="relative">
          <Icon iconId="nrk-warning" color={warning.light} />
          <Box
            id={tooltipId}
            role="tooltip"
            as="span"
            position="absolute"
            top="0"
            right="-12px"
            transform="translateY(calc(-100% - 7px - 8px))"
            bg={warning.light}
            color={warning.dark}
            p="16px"
            borderRadius="6px"
            fontSize="13px"
            fontWeight="400"
            lineHeight="18px"
            width="max-content"
            maxW={{ base: 'calc(90vw - 40px)', sm: '293px' }}
          >
            {warningMessage}
          </Box>
          <Box
            className="triangle"
            position="absolute"
            top="calc(-7px - 8px)"
            right="0"
            transform="translateX(-25%)"
            borderRight="8px solid transparent"
            borderBottom="8px solid transparent"
            borderLeft="8px solid transparent"
            borderTop={`8px solid ${warning.light}`}
          />
        </Flex>
      )}
    </Flex>
  )
}

SubmissionsInput.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  required: PropTypes.bool,
  pattern: PropTypes.string,
  warningMessage: PropTypes.string,
  onInput: PropTypes.func,
  valid: PropTypes.bool
}

export default SubmissionsInput
