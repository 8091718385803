import React, { forwardRef } from 'react'
import Dialog from '../Dialog/Dialog'
import { useColors } from '../theme/Color'
import { css } from '@emotion/react'
import Text from '../Text'
import Box from '../Box'
import IconButton from '../IconButton'
import { useAuthContext } from '../../components/Auth/AuthContext'
import Button from '../Button'

const SubmissionsAcceptTermsDialog = forwardRef(({ ...restProps }, ref) => {
  const { personal } = useColors()
  const { acceptForumTerms } = useAuthContext()
  return (
    <Dialog
      position="fixed"
      ref={ref}
      maxH="100dvh"
      maxW="335px"
      margin="0"
      padding="32px 24px 24px 24px"
      rounded="12px"
      shadow="0px 20px 50px 0px rgba(0, 0, 0, 0.25)"
      backgroundColor={personal.light}
      color={personal.mediumLight10}
      left="50%"
      top="50%"
      transform="translate(-50%, -50%)"
      css={css`
        & {
          width: 90%;
        }
        &::backdrop {
          background: rgba(7, 25, 47, 0.7);
        }
      `}
      onClick={event => {
        const rect = ref.current.getBoundingClientRect()
        const isInDialog =
          rect.top <= event.clientY &&
          event.clientY <= rect.top + rect.height &&
          rect.left <= event.clientX &&
          event.clientX <= rect.left + rect.width
        if (!isInDialog) {
          ref.current?.close?.()
        }
      }}
      {...restProps}
    >
      <Box>
        <Text fontSize="32px" fontWeight="800" lineHeight="normal" mb="8px">
          Før du sender melding...
        </Text>
        <Text fontSize="17px" fontWeight="400" lineHeight="24px" mb="24px">
          For å kunne sende melding må du godta noen ekstra vilkår.
        </Text>
        <Button onClick={acceptForumTerms} variantColor="personal" variant="login" rightIcon="nrk-open-in-new">
          Åpne vilkår
        </Button>
      </Box>
      <IconButton
        iconId="nrk-close"
        position="absolute"
        top="20px"
        right="20px"
        px="8px"
        height="32px"
        fontSize="10.667px"
        borderRadius="100px"
        onClick={() => ref.current.close()}
      />
    </Dialog>
  )
})

SubmissionsAcceptTermsDialog.displayName = 'SeriesSubmissionsAcceptTermsDialog'

export default SubmissionsAcceptTermsDialog
