import PropTypes from 'prop-types'
import React, { createContext, useCallback, useContext, useMemo, useReducer, useRef, useState } from 'react'
import { fetchSeriesSubmissionSettings } from '../../apiClients/interaction'
import SeriesSubmissionsSubmissionDialog from './SeriesSubmissionsSubmissionDialog'
import SubmissionsLoginDialog from '../../RadioUI/Submissions/SubmissionsLoginDialog'
import SubmissionsAcceptTermsDialog from '../../RadioUI/Submissions/SubmissionsAcceptTermsDialog'

const SERIES_SUBMISSIONS_LOADING = 'SERIES_SUBMISSIONS_LOADING'
const SERIES_SUBMISSIONS_LOADED = 'SERIES_SUBMISSIONS_LOADED'
const SERIES_SUBMISSIONS_FAILED = 'SERIES_SUBMISSIONS_FAILED'

export const SeriesSubmissionsLoading = seriesId => {
  return {
    seriesId,
    type: SERIES_SUBMISSIONS_LOADING,
    loading: true
  }
}

export const SeriesSubmissionsLoaded = (seriesId, payload) => {
  return {
    seriesId,
    type: SERIES_SUBMISSIONS_LOADED,
    loading: false,
    payload
  }
}

export const SeriesSubmissionsFailed = (seriesId, error) => {
  return {
    seriesId,
    type: SERIES_SUBMISSIONS_FAILED,
    loading: false,
    error
  }
}

const reducer = (state = { series: {} }, action) => {
  switch (action.type) {
    case SERIES_SUBMISSIONS_LOADING: {
      return {
        series: {
          ...state.series,
          [action.seriesId]: { loading: action.loading }
        }
      }
    }
    case SERIES_SUBMISSIONS_LOADED: {
      return {
        series: {
          ...state.series,
          [action.seriesId]: { submissions: action.payload.submissions, loading: action.loading }
        }
      }
    }
    case SERIES_SUBMISSIONS_FAILED: {
      return {
        series: {
          ...state.series,
          [action.seriesId]: { error: action.error.toString(), loading: action.loading }
        }
      }
    }
    default: {
      return state
    }
  }
}

const defaultDialogProperties = {
  open: false,
  showModal: () => {}
}

const SeriesSubmissionsContext = createContext({
  loginDialog: { ...defaultDialogProperties },
  acceptTermsDialog: { ...defaultDialogProperties },
  submissionDialog: { ...defaultDialogProperties },
  fetchSeriesSubmissions: async seriesId => {},
  series: {},
  setCurrentSeriesSubmissionsMetadata: () => {}
})

export const SeriesSubmissionsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer)
  const loginDialogRef = useRef()
  const acceptTermsDialogRef = useRef()
  const [submissionDialoHidden, setSubmissionDialoHidden] = useState(true)
  const [currentSeriesSubmissionsMetadata, setCurrentSeriesSubmissionsMetadata] = useState({
    seriesId: '',
    seriesTitle: '',
    submissions: {
      enabled: false,
      text: { enabled: false, maxLength: 1000 },
      audio: { enabled: false },
      video: { enabled: false },
      image: { enabled: false }
    }
  })

  const fetchSeriesSubmissions = useCallback(seriesId => {
    const SeriesSubmissionsAbortController = new AbortController()
    dispatch(SeriesSubmissionsLoading(seriesId))

    const loadDataPromise = fetchSeriesSubmissionSettings(seriesId, { signal: SeriesSubmissionsAbortController.signal })

    loadDataPromise
      .then(payload => {
        dispatch(SeriesSubmissionsLoaded(seriesId, payload))
      })
      .catch(error => {
        dispatch(SeriesSubmissionsFailed(seriesId, error))
      })

    return {
      loadDataPromise,
      abort: () => {
        SeriesSubmissionsAbortController.abort()
      }
    }
  }, [])

  const value = useMemo(() => {
    return {
      loginDialog: {
        open: loginDialogRef.current?.open,
        showModal: () => loginDialogRef.current?.showModal()
      },
      acceptTermsDialog: {
        open: acceptTermsDialogRef.current?.open,
        showModal: () => acceptTermsDialogRef.current?.showModal()
      },
      submissionDialog: {
        open: !submissionDialoHidden,
        showModal: () => setSubmissionDialoHidden(false)
      },
      fetchSeriesSubmissions,
      setCurrentSeriesSubmissionsMetadata,
      ...state
    }
  }, [fetchSeriesSubmissions, state, submissionDialoHidden, setCurrentSeriesSubmissionsMetadata])
  return (
    <SeriesSubmissionsContext.Provider value={value}>
      {children}
      <SubmissionsLoginDialog ref={loginDialogRef} />
      <SubmissionsAcceptTermsDialog ref={acceptTermsDialogRef} />
      <SeriesSubmissionsSubmissionDialog
        hidden={submissionDialoHidden}
        setHidden={setSubmissionDialoHidden}
        seriesSubmissions={currentSeriesSubmissionsMetadata}
      />
    </SeriesSubmissionsContext.Provider>
  )
}

SeriesSubmissionsProvider.propTypes = {
  children: PropTypes.node
}

export const useSeriesSubmissions = () => {
  const context = useContext(SeriesSubmissionsContext)
  return context
}

export const useFetchSeriesSubmissions = seriesId => {
  const { fetchSeriesSubmissions } = useContext(SeriesSubmissionsContext)
  return useCallback(() => fetchSeriesSubmissions(seriesId), [fetchSeriesSubmissions, seriesId])
}

export const useSeriesSubmissionsSettings = seriesId => {
  const { series } = useContext(SeriesSubmissionsContext)
  return useMemo(() => series?.[seriesId], [series, seriesId])
}
