import React, { useEffect, useRef, useState } from 'react'
import CoreDialog from '@nrk/core-dialog/jsx'
import { useColors } from '../../RadioUI/theme/Color'
import { css } from '@emotion/react'
import Text from '../../RadioUI/Text'
import IconButton from '../../RadioUI/IconButton'
import Flex from '../../RadioUI/Flex'
import Box from '../../RadioUI/Box'
import SubmissionsLockedFormField from '../../RadioUI/Submissions/SubmissionsLockedFormField'
import Checkbox from '../Checkbox'
import { useLoginSession } from '../Auth/AuthContext'
import SubmissionsInput from '../../RadioUI/Submissions/SubmissionsInput'
import SubmissionsTextArea from '../../RadioUI/Submissions/SubmissionsTextArea'
import Button from '../../RadioUI/Button'
import { usePostSeriesSubmission } from '../../apiClients/interaction'
import Toast from '../Toast'
import Icon from '../../RadioUI/Icon'
import PropTypes from 'prop-types'
import { get, set } from 'idb-keyval'
import { mq } from '../../RadioUI/theme/breakpoints'
import { IDB_KEY_SUBMISSION_PHONE_NUMBER } from '../../RadioUI/Submissions/helpers'
import { spCategory, trackSnowplowEvent } from '../../clientMonitoring'
import usePageScroll from '../../common/disablePageScroll'

const FORM_FIELDS = {
  message: 'message',
  anonymous: 'anonymous',
  phone: 'phone'
}

const SeriesSubmissionsSubmissionDialog = ({
  hidden,
  setHidden,
  seriesSubmissions: {
    seriesId,
    seriesTitle,
    submissions: {
      text: { maxLength }
    }
  },
  ...restProps
}) => {
  const { main, warning } = useColors()
  const postSeriesSubmission = usePostSeriesSubmission()
  const { user } = useLoginSession()
  const [message, setMessage] = useState('')
  const [anonymous, setAnonymous] = useState(false)
  const [phone, setPhone] = useState('')
  const [phoneValid, setPhoneValid] = useState(true)
  const dialogPaddingX = '20px'
  const [loading, setLoading] = useState(false)
  const [toastHidden, setToastHidden] = useState(true)
  const [warningToastHidden, setWarningToastHidden] = useState(true)
  const [apiErrorPhone, setApiErrorPhone] = useState(null)
  const [apiErrorMessage, setApiErrorMessage] = useState(false)
  const nameFieldRef = useRef()
  const emailFieldRef = useRef()
  const pageScroll = usePageScroll()

  const clearForm = () => {
    setMessage('')
    setAnonymous(false)

    setApiErrorMessage(false)
    setApiErrorPhone(false)
    setPhoneValid(true)
  }

  useEffect(() => {
    if (!hidden) {
      pageScroll.disable()

      get(IDB_KEY_SUBMISSION_PHONE_NUMBER)
        .then(idbPhone => setPhone(idbPhone ?? ''))
        .catch(() => {})
    } else {
      pageScroll.enable()
    }
  }, [hidden, pageScroll])

  return (
    <>
      <Box
        onClick={event => {
          nameFieldRef.current.handleScreenClick(event)
          emailFieldRef.current.handleScreenClick(event)
        }}
      >
        <CoreDialog
          css={css`
            & {
              position: fixed;
              max-height: 100dvh;
              overflow-y: scroll;
              overscroll-behavior: contain;
              max-width: 500px;
              margin: 0;
              padding: 0;
              border-radius: 12px;
              box-shadow: 0px 20px 50px 0px rgba(0, 0, 0, 0.25);
              background-color: ${main.medium};
              color: ${main.contrastLight};
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
              z-index: 1400;
            }
            & + backdrop {
              z-index: 1399;
              opacity: 1;
              background-color: rgba(7, 25, 47, 0.7);
            }
            ${mq({
              width: ['100%', '90%'],
              height: ['100dvh', 'auto']
            })}
          `}
          className="core-dialog"
          hidden={hidden}
          onDialogToggle={event => {
            if (!event.target.hidden) return
            if (message === '') {
              setHidden(true)
              clearForm()
              return
            }

            const openTime = new Date()
            const wantToClose = confirm('Vil du lukke innsending? \nMeldingen din blir slettet.')
            const closeTime = new Date()

            if (closeTime - openTime < 10) {
              // dialog blocked
              setHidden(true)
              clearForm()
            } else {
              if (wantToClose) {
                setHidden(true)
                clearForm()
              } else {
                event.target.hidden = false
                setHidden(false)
              }
            }
          }}
          {...restProps}
        >
          <Box
            as="form"
            position="relative"
            onSubmit={event => {
              event.preventDefault()
              trackSnowplowEvent(spCategory.SubmissionPage, 'SendButtonTapped', seriesId)

              const formData = new FormData(event.target)
              const message = formData.get(FORM_FIELDS.message)
              const anonymous = formData.has(FORM_FIELDS.anonymous)
              const phone = formData.get(FORM_FIELDS.phone)

              setLoading(true)
              postSeriesSubmission(seriesId, { acceptTerms: true, anonymous, phone, message }).then(
                ({ ok, errors }) => {
                  setLoading(false)
                  if (ok) {
                    setToastHidden(false)
                    setMessage('') // empty message means "safe to close dialog"
                    setHidden(true)
                    set(IDB_KEY_SUBMISSION_PHONE_NUMBER, phone).catch(() => {})
                  } else {
                    errors.forEach(error => {
                      switch (error.name) {
                        case 'phone':
                          if (error.errorCode === 'InvalidFormat') {
                            setApiErrorPhone('Ugyldig format')
                          } else {
                            setApiErrorPhone('Noe gikk galt')
                          }
                          break
                        case 'message':
                          setApiErrorMessage(true)
                          break
                      }
                    })
                    setWarningToastHidden(false)
                  }
                }
              )
            }}
          >
            <Box paddingX={dialogPaddingX}>
              <Flex height="72px" align="center" justify="center">
                <Text fontSize="17px" fontWeight="700" lineHeight="22px">
                  Send inn
                </Text>
              </Flex>
              <SubmissionsTextArea
                placeholder={'Skriv en melding til ' + seriesTitle}
                maxLength={maxLength}
                value={message}
                border={apiErrorMessage || message.length === maxLength ? `1px solid ${warning.light}` : null}
                borderRadius="3px"
                onInput={e => {
                  setMessage(e.target.value)
                  if (apiErrorMessage) {
                    setApiErrorMessage(false)
                  }
                }}
                bottomLabelColor={apiErrorMessage || message.length === maxLength ? warning.light : '#ffffffB3'}
                name={FORM_FIELDS.message}
              />
              <Text
                fontSize="12px"
                fontWeight="700"
                lineHeight="16px"
                opacity="0.7"
                paddingTop="16px"
                paddingBottom="8px"
              >
                Kontaktinfo
              </Text>
              <SubmissionsLockedFormField
                label="Navn"
                value={user?.name}
                lockhint="Navnet er koblet til brukeren din og kan ikke endres. Du kan velge å ikke vise navnet ditt ved å huke av under."
                ref={nameFieldRef}
              />
              <Checkbox
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                width="100%"
                p="16px 15px 16px 12px"
                checked={anonymous}
                setChecked={setAnonymous}
                name={FORM_FIELDS.anonymous}
              >
                <Text fontSize="13px" fontWeight="400" lineHeight="18px">
                  Jeg vil ikke at navnet mitt skal brukes
                </Text>
              </Checkbox>
              <SubmissionsLockedFormField
                label="E-post"
                value={user?.email}
                lockhint="E-posten er koblet til brukeren din og kan ikke endres."
                mb="8px"
                ref={emailFieldRef}
              />
              <SubmissionsInput
                label="Telefonnummer"
                value={phone}
                type="tel"
                name={FORM_FIELDS.phone}
                pattern="\d+"
                warningMessage={apiErrorPhone || 'Telefonnummeret inneholder ugyldige tegn'}
                onInput={e => {
                  setPhone(e.target.value)
                  setPhoneValid(e.target.checkValidity())
                  if (apiErrorPhone) {
                    setApiErrorPhone(null)
                  }
                }}
                valid={!apiErrorPhone && phoneValid}
                mb="24px"
              />
            </Box>
            <hr
              style={{
                borderColor: main.contrastLight,
                opacity: '0.1'
              }}
            />
            <Flex width="100%" justifyContent="center" paddingTop="16px">
              <Button
                mb="32px"
                px="60px"
                variantColor="main"
                variant="light"
                type="submit"
                disabled={!message || !phoneValid || apiErrorPhone || apiErrorMessage}
              >
                Send
              </Button>
            </Flex>
            <IconButton
              aria-label="Lukk innsendingsdialog"
              iconId="nrk-close"
              position="absolute"
              top="20px"
              right="20px"
              px="8px"
              height="32px"
              fontSize="10.667px"
              borderRadius="100px"
              onClick={() => {
                trackSnowplowEvent(spCategory.SubmissionPage, 'CloseButtonTapped', seriesId)
                setHidden(true)
              }}
            />
          </Box>
          {loading && (
            <Flex
              position="absolute"
              style={{ inset: '0' }}
              backgroundColor="#07192fB3"
              justifyContent="center"
              alignItems="center"
            >
              <Icon
                iconId="nrk-spinner"
                fontSize="33.34px"
                css={css`
                  @keyframes rotate {
                    to {
                      transform: rotate(360deg);
                    }
                  }
                  & {
                    animation: rotate 1.5s linear infinite;
                  }
                `}
              />
            </Flex>
          )}
        </CoreDialog>
      </Box>
      <Toast isHidden={toastHidden} setIsHidden={setToastHidden}>
        Melding sendt
      </Toast>
      <Toast isHidden={warningToastHidden} setIsHidden={setWarningToastHidden} isWarning={true}>
        Noe gikk galt. Meldingen ble ikke sendt.
      </Toast>
    </>
  )
}

SeriesSubmissionsSubmissionDialog.propTypes = {
  hidden: PropTypes.bool,
  setHidden: PropTypes.func,
  seriesSubmissions: PropTypes.object
}

export default SeriesSubmissionsSubmissionDialog
