import React from 'react'
import PropTypes from 'prop-types'
import CustomButton from '../../RadioUI/CustomButton'
import Text from '../../RadioUI/Text'
import { MarkCompleted, UnMarkCompleted } from './MarkCompletedIcons'
import { useColors } from '../../RadioUI/theme/Color'

const MarkCompletedButton = ({
  onClick,
  loading,
  completed,
  completedText = 'Marker som ikke hørt',
  uncompletedText = 'Marker som hørt',
  ...restProps
}) => {
  const colors = useColors()
  const buttonText = completed ? completedText : uncompletedText

  return (
    <CustomButton
      data-test={buttonText}
      aria-label={buttonText}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      color={completed ? colors.warning.light : colors.main.contrastLight}
      disabled={loading}
      onClick={onClick}
      size="md"
      px={[2, 4]}
      {...restProps}
    >
      {completed ? <UnMarkCompleted /> : <MarkCompleted />}
      <Text pl={restProps.flexDir === 'row-reverse' ? '0' : '2'} className="MarkCompletedButton__text">
        {buttonText}
      </Text>
    </CustomButton>
  )
}

MarkCompletedButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  loading: PropTypes.bool,
  completed: PropTypes.bool,
  completedText: PropTypes.string,
  uncompletedText: PropTypes.string
}

export default MarkCompletedButton
