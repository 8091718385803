import React, { forwardRef } from 'react'
import Dialog from '../Dialog/Dialog'
import { useColors } from '../theme/Color'
import { css } from '@emotion/react'
import Text from '../Text'
import Box from '../Box'
import IconButton from '../IconButton'
import { useAuthContext } from '../../components/Auth/AuthContext'
import Button from '../Button'
import Icon from '../Icon'
import clientConfig from '../../clientConfig'

const SubmissionsLoginDialog = forwardRef(({ ...restProps }, ref) => {
  const { personal } = useColors()
  const { login } = useAuthContext()
  return (
    <Dialog
      position="fixed"
      ref={ref}
      maxH="100dvh"
      maxW="335px"
      margin="0"
      padding="32px 24px 24px 24px"
      rounded="12px"
      shadow="0px 20px 50px 0px rgba(0, 0, 0, 0.25)"
      backgroundColor={personal.light}
      color={personal.mediumLight10}
      left="50%"
      top="50%"
      transform="translate(-50%, -50%)"
      css={css`
        & {
          width: 90%;
        }
        &::backdrop {
          background: rgba(7, 25, 47, 0.7);
        }
      `}
      onClick={event => {
        const rect = ref.current.getBoundingClientRect()
        const isInDialog =
          rect.top <= event.clientY &&
          event.clientY <= rect.top + rect.height &&
          rect.left <= event.clientX &&
          event.clientX <= rect.left + rect.width
        if (!isInDialog) {
          ref.current?.close?.()
        }
      }}
      {...restProps}
    >
      <Box>
        <Text fontSize="32px" fontWeight="800" lineHeight="normal" mb="8px">
          Bli med på <br />
          lufta!
        </Text>
        <Text fontSize="17px" fontWeight="400" lineHeight="24px" mb="24px">
          Med NRK-brukeren din kan du delta i avstemninger og sende meldinger til ditt favorittprogram.
        </Text>
        <Button onClick={login} variantColor="personal" variant="login" rightIcon="nrk-open-in-new" mb="24px">
          Logg på
        </Button>
        <Box
          d="flex"
          flexDir="row"
          gap="20px"
          flexWrap="wrap"
          fontSize="16px"
          alignItems="center"
          color={personal.dark}
          lineHeight="0"
        >
          <a href="https://tv.nrk.no/" target="_blank" rel="noreferrer">
            <Icon iconId="nrk-logo-large-nrk-tv" />
          </a>
          <a href="https://radio.nrk.no/" target="_blank" rel="noreferrer">
            <Box
              as="img"
              h="14px"
              w="auto"
              src={`${clientConfig.DIST_PATH}/images/channel-logos/nrk-logo-nrk-radio--no-color.svg`}
              alt=""
              aria-hidden="true"
            />
          </a>
          <a href="https://nrksuper.no/" target="_blank" rel="noreferrer">
            <Icon iconId="nrk-logo-nrk-super" />
          </a>
          <Box as="a" href="https://yr.no/" target="_blank" rel="noreferrer" fontSize="12.31px">
            <Icon iconId="nrk-logo-yr" />
          </Box>
        </Box>
      </Box>
      <IconButton
        iconId="nrk-close"
        position="absolute"
        top="20px"
        right="20px"
        px="8px"
        height="32px"
        fontSize="10.667px"
        borderRadius="100px"
        onClick={() => ref.current.close()}
      />
    </Dialog>
  )
})

SubmissionsLoginDialog.displayName = 'SeriesSubmissionsLoginDialog'

export default SubmissionsLoginDialog
